<template>
  <div>
    <van-search v-model="value" placeholder="请输入车位号搜索地锁设备" @search="selectLockSeatList(value)" />
    <div class="list">
      <van-radio-group v-model="radio">
        <van-radio :name="it.id" v-for="(it,key) in lockList" :key="key" class="item">
          <p><span>停车场：</span>{{it.parkingName}}</p>
          <p><span>设备名：</span>{{it.deviceName}}</p>
          <p><span>序列号：</span>{{it.deviceSerial}}</p>
        </van-radio>
      </van-radio-group>
    </div>
    <div class="open">
      <p @click="openCloseLockById(1)">升锁</p>
      <p @click="openCloseLockById(2)">降锁</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "open",
  title: "紧急降锁指令",
  data() {
    return {
      value: "",
      radio: "",
      lockList: [],
    };
  },
  created() {
    this.$parent.hiddenBack();
  },
  methods: {
    selectLockSeatList(spaceNum) {
      this.$http.post(
        "/park-service-park/device/selectLockSeatList",
        { spaceNum },
        (res) => {
          this.lockList = res.list;
        }
      );
    },
    openCloseLockById(type) {
      let id = this.radio;
      this.$http.post(
        "/park-service-park/device/openCloseLockById",
        { id, type },
        (res) => {
          this.$toast.success("指令下发成功！");
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  height: calc(100% - 124px);
  overflow-y: auto;
  .item {
    width: 6.5rem;
    margin: 0.2rem auto;
    background-color: #fff;
    border-radius: 5px;
    padding: 0.2rem;
    p {
      color: #4e4e4e;
      font-size: 0.3rem;
      margin: 0.15rem;
      span {
        color: #1f2020;
      }
    }
  }
}
.open {
  height: 70px;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  p {
    width: 2rem;
    height: 40px;
    line-height: 40px;
    font-size: 0.32rem;
    color: #fff;
    border-radius: 5px;
    background-color: #67c23a;
    text-align: center;
    &:last-child {
      background-color: #f56c6c;
    }
  }
}
</style>