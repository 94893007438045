<template>
  <div class="plate">
    <div class="parking">
      <img src="@/assets/parking.png" alt="" srcset="">
    </div>
    <input type="text" v-model="plate" placeholder="请输入车牌号，例如：桂P12992">
    <p>*字母自动转大写已开启</p>
    <button type="submit" @click="search">查询</button>
  </div>
</template>

<script>
export default {
  name: "searchPlate",
  title: "车牌号查询",
  data() {
    return {
      plate: "",
    };
  },
  watch: {
    plate: function (value) {
      this.plate = value.toUpperCase();
    },
  },
  created() {
    this.$parent.hiddenBack();
  },
  methods: {
    search() {
      if (this.plate.length > 6) {
        this.$router.push("/bill?plate=" + this.plate);
      } else {
        this.$toast.fail("请输入正确的车牌号");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.plate {
  height: 100%;
  background-color: #fff;
  .parking {
    text-align: center;
    padding: 0.9rem 0 0.5rem 0;
    img {
      width: 6.2rem;
      height: auto;
    }
  }
  input {
    display: block;
    width: 6.5rem;
    height: 0.9rem;
    margin: 0.3rem auto;
    background: #f3f3f3;
    border-radius: 8px;
    border: 0;
    text-align: center;
    font-size: 0.3rem;
  }
  p{
      text-align: center;
      color: #9e9e9e;
  }
  button {
    display: block;
    width: 6.5rem;
    height: 0.9rem;
    margin: 2rem auto 0 auto;
    background: #1ca6ff;
    border-radius: 8px;
    border: 0;
    text-align: center;
    font-size: 0.3rem;
    color: #fff;
  }
}
</style>